.payment-options button{
    width: 200px;
    height: 50px;
}

.payment-options button .lab{
    font-size: 50px;
}

.payment-options button:first-child:hover, .payment-options button:first-child:active{
    background: transparent;
    color: green;
}

.payment-options button:hover{
    background: transparent;
    color: blue; 
}

.bi-credit-card{
    font-size: 25px;
    margin-right: 10px;
}

.purchase{
    background-color: rgb(0, 223, 0);
    height: 50px;
    width: 300px;
    border-radius: 20px;
    margin-top: 30px;
}

.purchase:hover{
    border: 2px solid rgb(0, 223, 0);
    color: rgb(0, 223, 0);
}