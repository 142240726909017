.ready-row .card-body{
    height: 450px;
    text-align: center;
    cursor: pointer;
}

.ready-row .card-body img{
    height: 70%;
    margin-top: 20px;
    mix-blend-mode:multiply;
}

.product-details{
    width: 85%;
}

.product-details i{
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
}

.product-details i:hover{
    background-color: rgb(238, 238, 238);
}

.style-card{
    min-width: 350px;
    max-width: 360px;
    margin-right: 10px;
}

.ready-row{
    display: flex;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
}

@media only screen and (max-width: 600px){
    .ready-products{
        position: relative;
        top: -280px;
        margin-bottom: 40px;
    }
    .style-card{
        min-width: 280px;
        max-width: 300px;
        margin-right: 10px;
    }
}