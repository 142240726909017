.progress{
    margin-top: 120px;
    display: flex;
    justify-content: center;
    height: 100px;
    background-color: transparent;
}

.progress-indicator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon {
    font-size: 24px;
    color: #333;
    margin-bottom: 8px;
    height: 40px;
    width: 40px;
    border: 2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    z-index: 1;
}

.text {
    font-size: 14px;
    color: rgb(0, 0, 0);
    font-size: 14px;
}

.line {
    height: 2px;
    width: 300px;
    margin-bottom: 30px;
    background-color: #000000;
}

@media only screen and (min-width: 900px){
    .progress-indicator{
        width: 600px;
    }
    .line{
        width: 300px;
        margin-bottom: 30px;
    }
}