

.order-quantity input{
    width: 100px;
    text-align: center;
    height: 40px;
    padding: 0px;
}

.order-quantity button{
    font-size: 18px;
    margin-left: 10px;
    margin-right: 10px;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image img{
    height: 230px;
    width: auto;
}

@media only screen and (min-width: 600px){
    .offcanvas{
        min-width: 600px;
    }
}