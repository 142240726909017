footer{
    position: relative;
    top: 50px;
}

input{
    height: 50px;
}

.send-btn{
    width: 200px;
    height: 45px;
}

@media only screen and (max-width:600px) {
    footer{
        position: relative;
        top: -180px;
    }
}
